import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import Box from 'common/src/components/Box';
import Text from 'common/src/components/Text';
import Heading from 'common/src/components/Heading';
//import Button from 'common/src/components/Button';
import FeatureBlock from 'common/src/components/FeatureBlock';
import Container from 'common/src/components/UI/Container';
import Particles from '../../Agency/Particle';
//import BannerWrapper, { DiscountLabel } from './bannerSection.style';
import BannerWrapper from './bannerSection.style';
//import TextWrapper from './bannerSection.style';

const BannerSection = ({
  row,
  col,
  title,
  btnStyle,
  description,
  discountText,
  discountAmount,
  outlineBtnStyle,
}) => {
  const ButtonGroup = () => (
    <Fragment>
      {/*<Button title="GET STARTED" {...btnStyle} />
       <Button
        title="WATCH WORKS"
        variant="textButton"
        icon={<i className="flaticon-next" />}
        {...outlineBtnStyle}
      /> */}
    </Fragment>
  );
  return (
    <BannerWrapper>
      <Particles />
      <Container>
        <Box className="row" {...row}>
          <Box className="col" {...col}>
            <h1 style={{fontSize: "65px", fontWeight: "500", margin: "0", color: "#3D636C"}}>COVID-19 Testing for Employees</h1>
            <FeatureBlock
              title={
                <Heading
                  content=""
                  {...title}
                />
              }
              description={
                <Fragment>
                <Text
                  content="The COVID-19 pandemic has been a time of a lot of uncertainty for employees and employers alike. Employers want to be proactive in protecting the health of their employees and customers, but often need help deciding how and where to offer testing for SARS CoV-2, the virus that causes COVID-19."
                  {...description}
                />
                <br/>
                <Text
                  content="The CDC has set forth five scenarios when COVID-19 testing for employees is important."
                  {...description}
                />
                </Fragment>
              }
              button={<ButtonGroup />}
            />    

            
                
          </Box>
        </Box>    
        <div id="article-content">
        <Heading as="h2" content="First Scenario: High Temp During Daily Temperature Readings"/>
    <Text content="The first COVID-19 testing scenario should occur immediately after employees are found to have high temperatures during a round of daily temperature checks. The employee with a high temperature or who is showing any other signs of infection should be sent home to self-isolate while test results are pending."/>
    <br/>
    <Text content="If an employee has been around somebody who has tested positive for COVID-19, or has other close contacts, this is another scenario in which somebody would need to be tested immediately and sent home while the results of the test are pending."/>
    <br/><br/>

    <Heading as="h2" content="Second Scenario: Testing Positive for COVID"></Heading>
    <Text content="In professions where workers are working closely together, such as in fast food or warehouse jobs, mass testing may be appropriate when one person tests positive."/>
    <br/><br/>

    <Heading as="h2" content="Third Scenario: Operation Shift for COVID"></Heading>
    <Text content="The Centers for Disease Control (CDC) recommends that employers consider how they plan to change their operations in the event of mass exposure to SARS-CoV-2, the virus that causes COVID-19. Anyone with positive testing results should be sent home to quarantine immediately. 
"/>
    <br/><br/>

    <Heading as="h2" content="Fourth and Fifth Scenarios for Quarantine"></Heading>
    <Text content="The CDC states that an employee who has previously tested positive for COVID-19 and is considered to be a confirmed COVID-19 case may still test positive if he or she is tested again afterwards with antibody tests and viral tests. Because most people experience mild symptoms of illness, after roughly 14 days, formerly positive cases should be able to discontinue self-quarantine and come back to work. When a person tests positive at work, sometimes others in the company are also tested to evaluate the transmission and the possibility of an outbreak."/>    
    <br/>
    <Text content="Several state and local health care systems offer immediate testing for COVID-19, and this applies to all kinds of work spaces, both small and large. The best approach to public health includes COVID-19 testing with a commitment from employers to ensure the confidentiality of medical testing for their employees and employee health and wellness programs. Workplaces should continue to offer these five testing scenarios until case numbers drop substantially.
"></Text><br/>
<Text content="Just because COVID-19 testing is crucial for a healthy workforce, though, doesn’t always mean that it is convenient for employees. That’s why Archer Health offers onsite COVID-19 testing that makes it easy to take proactive measures to protect your employees’ and customers’ health. We use FDA approved instruments for the testing, and we offer rapid results -- within just 15 minutes."></Text><br/>
<Text content="Contact us today to learn more about our onsite testing and other onsite health services!"></Text>
    </div>  
      </Container>
      
    </BannerWrapper>
  );
};

BannerSection.propTypes = {
  title: PropTypes.object,
  btnStyle: PropTypes.object,
  description: PropTypes.object,
  contentStyle: PropTypes.object,
  discountText: PropTypes.object,
  discountAmount: PropTypes.object,
  outlineBtnStyle: PropTypes.object,
};

BannerSection.defaultProps = {
  row: {
    flexBox: true,
    flexWrap: 'wrap',
    ml: '-15px',
    mr: '-15px',
    alignItems: 'center',
  },
  col: {
    pr: '15px',
    pl: '15px',
    width: ['100%', '100%', '60%', '50%'], //<480, <768, >768, >990
  },
  title: {
    fontSize: ['16px', '24px', '32px', '45px'],
    fontWeight: '300',
    color: '#3D636C',
    //letterSpacing: '-0.025em',
    letterSpacing: 0,
    mb: ['20px', '25px'],
    lineHeight: '1.31',
  },
  description: {
    fontSize: ['14px','16px','20px'],
    color: '#3D636C',
    lineHeight: '1.5',
    mb: '0',
  },
  btnStyle: {
    minWidth: ['120px', '156px'],
    fontSize: '14px',
    fontWeight: '300',
  },
  outlineBtnStyle: {
    minWidth: ['130px', '156px'],
    fontSize: '14px',
    fontWeight: '500',
    color: '#0f2137',
    p: '5px 10px',
  },
  discountAmount: {
    fontSize: '14px',
    color: '#10AC84',
    mb: 0,
    as: 'span',
    mr: '0.4em',
  },
  discountText: {
    fontSize: '14px',
    color: '#0f2137',
    mb: 0,
    as: 'span',
  },
};

export default BannerSection;
